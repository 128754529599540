<template>
  <v-container id="NotFoundView" fill-height fluid>
    <v-row align="center" justify="center">
      <div class="text-center">
        <v-icon x-large color="white" class="pb-1">mdi-cctv-off</v-icon>
        <h1 class="white--text">404 PAGE NOT FOUND</h1>
        <v-btn outlined class="mt-6" color="white" @click="backToHomeClicked">
          Back to Home
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundView",
  methods: {
    backToHomeClicked() {
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style scoped>
#NotFoundView {
  background-color: #00b39b;
}
</style>
